
























import { Component, Vue, Prop } from 'vue-property-decorator'
import './styles.scss'

@Component({
  name: 'ChromiumMic',
})
export default class ChromiumMic extends Vue {
  /**
   * Lang for custom screenshots
   */
  @Prop({
    type: String,
    default: 'ru',
  })
  readonly lang!: 'ru' | 'en';

  /**
   * Current host
   */
  @Prop({
    type: String,
    required: true,
    default: '',
  })
  readonly host!: string;
}
